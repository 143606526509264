/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cell, flexRender, Header, Row, Table } from '@tanstack/react-table';
import { useItemSelection } from '../../../contexts/select-items/SelectItemsContext';
import { ClientModule } from '../../../models/ClientModule';
import { ColumnConfig } from '../../../models/TableView';
import TableViewColumnFilterMenu from '../../table-view/TableViewColumnFilterMenu';
import ContextMenu, { ContextMenuItem } from '../ContextMenu';
import SorterIcon from '../icon/SorterIcon';
import Loader from '../Loader';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import { useTableViewStyles } from '../../../hooks/useTableViewStyles';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import Tooltip from '../Tooltip';
import Button, { ButtonType } from '../form-control/Button';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';
import InfoIcon from '../icon/InfoIcon';
import { Heading, HeadingSize } from '../text/Heading';
import { useTranslation } from 'react-i18next';
import FunnelIcon from '../icon/FunnelIcon';
import { EMPTY_ARRAY, SELECT_COLUMN_ID, ROW_NUMBER_COLUMN_ID, PerPageCount } from '../../table-view/TableViewConstants';

type DataTableLayoutProps = {
  table: Table<any>;
  hasData: boolean;
  isFetching: boolean;
  totalRecords: number;
  dataCellContent?: (cell: Cell<any, unknown>) => string | number | boolean | JSX.Element | Iterable<ReactNode> | null | undefined;
  contextItems?: (row: Row<any>) => ContextMenuItem[];
  clientModule?: ClientModule;
  setScrollContainerRef: (el: HTMLDivElement) => void;
  infiniteLoadingRef: (noe: any) => void;
  rowLinkRoute?: (row: Row<any>) => string;
  selectedTemplateId?: string;
  filterColumnBlacklist?: string[];
};

const DataTableLayout = withSlot<DataTableLayoutProps, SlotDefinitions<['Empty']>>((props) => {
  const {
    table,
    hasData,
    isFetching,
    totalRecords,
    dataCellContent,
    contextItems,
    clientModule,
    setScrollContainerRef,
    infiniteLoadingRef,
    rowLinkRoute,
    selectedTemplateId,
    filterColumnBlacklist = EMPTY_ARRAY,
  } = props;
  const { t } = useTranslation(['data-table']);
  const { selection, mutateSelection, SelectionBarContent } = useItemSelection();

  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const { getCommonPinningStyles } = useTableViewStyles();
  const handleToggleFilterMenu = useCallback(
    (value: boolean, columnIndex: number) => {
      if (value && selectedColumnIndex !== columnIndex) {
        setSelectedColumnIndex(columnIndex);
      } else if (!value && selectedColumnIndex === columnIndex) {
        setSelectedColumnIndex(null);
      }
    },
    [selectedColumnIndex],
  );

  const headerCellContent = useCallback((header: Header<any, unknown>) => {
    const content = flexRender(header.column.columnDef.header, header.getContext());
    if (header.column.columnDef.id === SELECT_COLUMN_ID || header.column.columnDef.id === ROW_NUMBER_COLUMN_ID) return content;
    return (
      <Tooltip text={content} truncatedTextMode>
        {(tooltip) => (
          <div className="select-none truncate" {...tooltip}>
            {content}
          </div>
        )}
      </Tooltip>
    );
  }, []);

  const rowsSelectedText = useMemo(() => {
    if (selection.selectedCount > 0 && totalRecords) {
      if (selection.selectedCount < PerPageCount) {
        return t('data-table:multi-select.some-records-selected', { amount: selection.selectedCount });
      } else if (selection.selectedCount === PerPageCount) {
        return (
          <>
            {t('data-table:multi-select.all-page-records-selected', {
              amount: selection.selectedAll ? totalRecords : selection.selectedCount,
            })}
            {selection.selectedCount < totalRecords && (
              <>
                {!selection.selectedAll && (
                  <Button type={ButtonType.TERTIARY} className="ml-2 !p-0" onClick={() => mutateSelection.selectAll(true)}>
                    {t('data-table:multi-select.select-all-button', {
                      amount: totalRecords,
                      collection: 'todo',
                    })}
                  </Button>
                )}
                {selection.selectedAll && (
                  <Button
                    type={ButtonType.TERTIARY}
                    className="ml-2 !p-0"
                    onClick={() =>
                      mutateSelection.clear(
                        table.getRowModel().rows.map((row: Row<any>) => {
                          return { id: row.original.id };
                        }),
                      )
                    }
                  >
                    {t('data-table:multi-select.clear')}
                  </Button>
                )}
              </>
            )}
          </>
        );
      }
    }
    return '';
  }, [selection.selectedCount, selection.selectedAll, totalRecords, t, mutateSelection, table]);

  const EmptyElement = useMemo(
    () => (
      <div data-cy="list-empty" className="flex h-full w-full items-center justify-center">
        <div className="text-center">
          <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <Heading size={HeadingSize.H4} className="my-4">
            {t('empty.heading')}
          </Heading>
        </div>
      </div>
    ),
    [t],
  );

  const EmptyPlaceholder = useSlot(props, 'Empty', EmptyElement);

  return (
    <div style={{ width: table.getTotalSize() }} ref={setScrollContainerRef} className="h-full max-h-full min-w-full max-w-0 overflow-auto bg-white">
      <div className="border-gray-5 sticky top-0 z-10 w-fit">
        {selection.active && (
          <div className="flex items-center bg-white px-2 py-3">{SelectionBarContent({ selectionText: rowsSelectedText, clientModule })}</div>
        )}
        {table.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="flex w-fit items-center">
            {headerGroup.headers.map((header, columnIndex) => (
              <div
                key={header.id}
                style={getCommonPinningStyles(header.column, ['bottom', 'right'])}
                className={`border-gray-5 ${header.column.id !== SELECT_COLUMN_ID ? 'hover:bg-background-1 pl-2 pr-6' : 'px-2'} group relative bg-white py-3 text-left font-normal ${selection.active ? 'border-t' : ''}`}
              >
                <div className="flex items-center justify-between gap-1">
                  {header.column.columnDef.enableSorting && (
                    <div
                      className={header.column.getCanSort() ? 'w-4 cursor-pointer select-none' : ''}
                      {...mouseAndKeyboardCallbackProps(header.column.columnDef.enableSorting ? header.column.getToggleSortingHandler() : undefined)}
                    >
                      <SorterIcon
                        className="text-accent-1 -mt-1 h-6 w-5 hover:opacity-100"
                        direction={header.column.getIsSorted() ? (header.column.getIsSorted() === 'asc' ? 'asc' : 'desc') : 'none'}
                      />
                    </div>
                  )}
                  <div
                    {...mouseAndKeyboardCallbackProps(header.column.columnDef.enableSorting ? header.column.getToggleSortingHandler() : undefined)}
                    className={`flex flex-grow items-center gap-1 ${header.column.id !== SELECT_COLUMN_ID && header.column.id !== ROW_NUMBER_COLUMN_ID ? 'truncate' : ''} ${header.column.columnDef.enableSorting && header.column.getCanSort() ? 'cursor-pointer' : ''}`}
                  >
                    {header.isPlaceholder ? null : headerCellContent(header)}
                    {(header.column.columnDef.meta as ColumnConfig)?.filter &&
                      header.column.columnDef.id !== SELECT_COLUMN_ID &&
                      header.column.columnDef.id !== ROW_NUMBER_COLUMN_ID && <FunnelIcon className="text-gray-2 float-right h-4 w-4 flex-shrink-0" />}
                  </div>

                  {header.column.columnDef.meta && header.column.columnDef.id && (
                    <div
                      className={`flex items-center transition-opacity duration-200 ${!filterColumnBlacklist.includes(header.column.columnDef.id) ? (selectedColumnIndex === columnIndex ? 'opacity-100' : 'opacity-0 group-hover:opacity-100') : 'invisible'} `}
                    >
                      <TableViewColumnFilterMenu
                        selectedTemplateId={selectedTemplateId as string}
                        onToggle={(value) => {
                          handleToggleFilterMenu(value, columnIndex);
                        }}
                        columnConfig={{
                          value: header.column.columnDef.id,
                          type: (header.column.columnDef.meta as ColumnConfig).type,
                          freeze: (header.column.columnDef.meta as ColumnConfig).freeze,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: header.column.getCanResize() ? `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}` : '',
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={`${hasData ? 'w-fit' : 'w-full'} items-center bg-white`}>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <Link key={row.id} to={rowLinkRoute ? rowLinkRoute(row) : '#'} className="group/row flex cursor-pointer items-stretch">
            {row.getVisibleCells().map((cell) => (
              <div
                key={cell.id}
                className={`group-hover/row:bg-background-1 relative flex flex-col justify-center whitespace-nowrap bg-white ${cell.column.id === SELECT_COLUMN_ID ? 'px-2' : 'pl-2 pr-6'} ${row.getIsSelected() ? 'bg-background-1' : 'bg-white'}`}
                style={getCommonPinningStyles(cell.column, ['bottom'])}
                {...mouseAndKeyboardCallbackProps((e) => {
                  if (cell.column.id === SELECT_COLUMN_ID) {
                    e.preventDefault();
                    e.stopPropagation();
                    mutateSelection.selectIndividual(row.original, !selection.selectionStatus[row.original.id]);
                  }
                })}
              >
                <SkeletonLoader ready type="row">
                  <div className="text-dpm-14 truncate py-2 text-gray-500">
                    {dataCellContent ? dataCellContent(cell) : flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </SkeletonLoader>
              </div>
            ))}
            {(selectedRowIndex === null || selectedRowIndex === rowIndex) && selectedColumnIndex === null && contextItems && (
              <div
                {...mouseAndKeyboardCallbackProps(() => setSelectedRowIndex(rowIndex))}
                className={`sticky bottom-0 right-0 top-0 z-10 flex items-center px-[2px] ${selectedRowIndex !== rowIndex ? 'opacity-0' : ''} transition-opacity duration-200 group-hover/row:opacity-100`}
              >
                <ContextMenu items={contextItems(row)} onMenuToggle={(value) => setSelectedRowIndex(value ? rowIndex : null)} />
              </div>
            )}
          </Link>
        ))}
        {!hasData && !isFetching && (
          <div className="mt-20 flex h-full w-full items-center justify-center">
            <EmptyPlaceholder />
          </div>
        )}

        <div ref={infiniteLoadingRef} className="my-4 flex justify-center">
          {isFetching && <Loader size={12} centered={false} />}
        </div>
      </div>
    </div>
  );
});

export default DataTableLayout;
